/* ScrollToTopArrow.css */

.arrow-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  
  .arrow-container.visible {
    opacity: 1;
  }
  
  .fa-arrow-up::before {
    content: "\f062"; /* Unicode for the up arrow icon from FontAwesome, you might need to adjust this */
    font-family: FontAwesome;
    font-size: 24px;
    color: #f00; /* Arrow color */
  }
  
  .scrollarrow {
    width: 41px;
    height: 40px;
    background-color: #b20000;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 35px;
  }
  
  .scrollarrow a {
    color: white;
  }